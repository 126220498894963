import React, { useState } from "react"
import Select from "react-select"
import ReactTooltip from "react-tooltip"
import Link from 'gatsby'

// import CabinetMaterial from './cabinetMaterialvalue'
import logo from "../images/SideTrayVertical.jpg"
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap"

class FalseCeilingAdvanceCalculator extends React.Component {
  constructor() {
    super()
    this.state = {
      measurement: "100",
      verticalMeasurement: "0",
      pricePerSft: "",
      pricePerSftVertical: "",
      noOfLightPoints: "9",
      noOfLightPointsBig: "6",
      total: "",
      noOfLightPointsSmall: "0",
      noOfSwitchBoard: "0",
      material: "",
      smallLedPrice: "250",
      bigLedPrice: "500",
      ropeLight: "0",
      switchboardPrice: "2500",
      ropeLightPrice:"70"
    }
  }

  handlemeasurement = event => {
    this.setState({
      measurement: event.target.value,
    })
  }

  handleRopeLight = event => {
    this.setState({
      ropeLight: event.target.value,
      //ropeLightPrice: event.target.value * 60,
    })
  }

  handleRopeLightPrice = event => {
    this.setState({
      ropeLightPrice: event.target.value,
    })
  }

  handlePricePerSft = event => {
    this.setState({
      pricePerSft: event.target.value,
    })
  }

  handleSwitchBoardPrice = event => {
    this.setState({
      switchboardPrice: event.target.value,
    })
  }

  handlePricePerSftVertical = event => {
    this.setState({
      pricePerSftVertical: event.target.value,
    })
  }

  handleverticalMeasurement = event => {
    this.setState({
      verticalMeasurement: event.target.value,
    })
  }

  handleMaterial = event => {
    this.setState({
      material: event.target.value,
      pricePerSft: event.target.value,
      pricePerSftVertical: event.target.value - 20,
    })
    console.log(event.target)
  }

  handleLightPoints = event => {
    this.setState({
      noOfLightPoints: event.target.value,
    })
  }

  handleNoOfSmallLights = event => {
    this.setState({
      noOfLightPointsSmall: event.target.value,
      // smallLedPrice: event.target.value * 500
    })
  }

  handleNoOfBigLights = event => {
    this.setState({
      noOfLightPointsBig: event.target.value,
    })
  }

  handleSmallLedPrice = event => {
    this.setState({
      smallLedPrice: event.target.value,
    })
  }
  handleBigLedPrice = event => {
    this.setState({
      bigLedPrice: event.target.value,
    })
  }

  handleNoOfSwitchBoard = event => {
    this.setState({
      noOfSwitchBoard: event.target.value,
    })
  }

  exe = event => {
    this.setState({
      total:
        (parseInt(this.state.measurement) * parseInt(this.state.pricePerSft)) +
        (parseInt(this.state.verticalMeasurement) * parseInt(this.state.pricePerSftVertical)) + 
        (parseInt(this.state.noOfLightPointsSmall) * parseInt(this.state.smallLedPrice)) +
        (parseInt(this.state.noOfLightPointsBig) * parseInt(this.state.bigLedPrice)) +
        (parseInt(this.state.ropeLight) * parseInt(this.state.ropeLightPrice)) +
        (parseInt(this.state.noOfSwitchBoard) * parseInt(this.state.switchboardPrice))
    })
    event.preventDefault()
  }

  render() {
    return (
      <div>
        <Form className="text-center" onSubmit={this.exe}>
          <FormGroup row>
            <Label for="id_total_sft" sm={4}>
              Total Area(Except Tray Vertical)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.measurement}
                onChange={this.handlemeasurement}
                placeholder="False Ceiling area in SFT"
                data-tip data-for="meas"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Tray Vertical
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.verticalMeasurement}
                onChange={this.handleverticalMeasurement}
                placeholder="Vertical measurement in SFT"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_cabinet_material" sm={4}>
              Ceiling Material
            </Label>
            <Col sm={4}>
              <select
                className="form-control mb-3"
                value={this.state.material}
                onChange={this.handleMaterial}
              >
                <option selected hidden enabled>
                  Select False Ceiling Material
                </option>
                <option value="70">Gypsum</option>
                <option value="60">POP</option>
                <option value="90">PVC</option>
              </select>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
            Price Per Sft(Ceiling Material + Channel + Wiring) - Without Tray Vertical
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.pricePerSft}
                onChange={this.handlePricePerSft}
                placeholder="Price per SFT"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
            Price Per Sft(Ceiling Material + Channel + Wiring) - Vertical
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.pricePerSftVertical}
                onChange={this.handlePricePerSftVertical}
                placeholder="Price Per SFT(Vertical*)"
              />
            </Col>
          </FormGroup>

          
          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              No of Big LED Lights
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.noOfLightPointsBig}
                onChange={this.handleNoOfBigLights}
                placeholder="No of lights above 6 watts"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Big LED Price(Rs)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.bigLedPrice}
                onChange={this.handleBigLedPrice}
                placeholder="Price per Big LED"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              No of Small LED Lights
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.noOfLightPointsSmall}
                onChange={this.handleNoOfSmallLights}
                placeholder="No of lights below 6 watts"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Small LED Price(Rs)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.smallLedPrice}
                onChange={this.handleSmallLedPrice}
                placeholder="Price per small LED"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Rope Lights(Meters)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.ropeLight}
                onChange={this.handleRopeLight}
                placeholder="Rope Light in Meter"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Rope Lights Price(Rs)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.ropeLightPrice}
                onChange={this.handleRopeLightPrice}
                placeholder="Rope Light in Meter"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              No of new Switch Boards
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.noOfSwitchBoard}
                onChange={this.handleNoOfSwitchBoard}
                placeholder="No of new Switch Board if any"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="id_countertop_material" sm={4}>
              Switchboard Price with Wall Modification(Rs)
            </Label>
            <Col sm={4}>
              <Input
                type="text"
                value={this.state.switchboardPrice}
                onChange={this.handleSwitchBoardPrice}
                placeholder="Switchboard Price in Rs"
              />
            </Col>
          </FormGroup>
          <FormGroup check row>
            <Col sm={{ size: 2, offset: 2 }}>
              <button className="btn btn-outline-success"> Total Price</button>

            </Col>
          </FormGroup>
          {/* <img src={logo} alt="Logo" width="400" height="400"/> */}
        </Form>
        <b>Total Cost:</b> {this.state.total} Rs
       
        
      </div>
    )
  }
}

export default FalseCeilingAdvanceCalculator
